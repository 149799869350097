import React, { useEffect, useMemo, useState } from "react";
import OTPInput from "react-otp-input";
import logo from "./../../assets/image/logo.png";
import { useNavigate, useParams } from "react-router-dom";

// Import style here
import style from "./OtpScreenStyle.css";
import apiCall from "../../utils/axiosCall";
import { endPoint } from "../../utils/endPoint";

const createInputRefs = (length) => {
  return Array.from({ length }, () => React.createRef());
};

const OtpScreen = ({ allowedOtpLength }) => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const inputRefs = useMemo(
    () => createInputRefs(allowedOtpLength),
    [allowedOtpLength]
  );
  const [otp, setOtp] = useState(""); //GCYPU8
  const [verificationDone, setVerificationDone] = useState(false);
  const [errorRender, setErrorRender] = useState(false);

  const isProduction = process.env.REACT_APP_NODE_ENV === "production";

  useEffect(() => {
    localStorage.clear();
    verificationLink();
    fetchInterviewType();
  }, []);

  const verificationLink = async () => {
    let data = { uuid };

    const verificationLinkDetails = await apiCall({
      url: endPoint.VERIFY_LINK,
      method: "post",
      data: JSON.stringify(data),
    });

    const decryptedDetails = verificationLinkDetails;
    if (decryptedDetails.return_code === "link_not_expired") {
      localStorage.setItem("uuid", uuid);
    } else {
      setVerificationDone(true);
    }
  };

  const fetchInterviewType = async () => {
    const interviewDetails = await apiCall({
      url: endPoint.AUTOSCRENN_INTERVIEW_TYPE,
      method: "post",
      data: JSON.stringify({ interview_id: uuid }),
    });
    localStorage.setItem(
      "resourceful_interview_type",
      interviewDetails.result.interviewType
    );
  };
  const handleChange = (otp) => {
    setErrorRender(false);
    setOtp(otp);
  };

  const handleClick = async () => {
    const interview_type =
      localStorage.getItem("resourceful_interview_type") || "AI";
    let formData = { uuid, otp, interview_type };
    console.log(formData);
    const otpVerificationDetails = await apiCall({
      url: endPoint.VERIFY_OTP,
      method: "post",
      data: JSON.stringify(formData),
    });
    // var id = "80369bea-a3fd-473b-82b4-64c7060144a9";
    // navigate(`/autoscreen/guidelines/${id}`);
    const decryptedDetails = otpVerificationDetails;

    if (decryptedDetails.return_code === "verified_otp_success") {
      localStorage.setItem("session_id", decryptedDetails.result.session_id);
      localStorage.setItem(
        "interview_id",
        decryptedDetails.result.interview_id
      );
      if (interview_type == "AI") {
        navigate(`/guidelines/${decryptedDetails.result.interview_id}`);
      } else if (interview_type == "Auto AI") {
        navigate(
          `/autoscreen/guidelines/${decryptedDetails.result.interview_id}`
        );
      }
    } else {
      setErrorRender(true);
    }
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  if (verificationDone) {
    return (
      <div>
        <h1 className='error_heading'>Sorry, the link has been expired</h1>
      </div>
    );
  }

  return (
    <>
      <div className='container_for_otp'>
        <div className='logo_container'>
          <img src={logo} alt='otp' />
          <h3 className='verification_font'>OTP Verification</h3>
        </div>
        <div className='Main_Container'>
          <div className='otp_bg_box lg:w-5/5 mx-auto'>
            <div className='input_container mx-auto'>
              <h2 className='text-center text-2xl font-semibold mb-8 main_heading_text'>
                Ready to Join?
              </h2>
              <h3 className='subText'>Enter OTP</h3>
              <OTPInput
                onChange={handleChange}
                value={otp}
                inputStyle='inputStyle'
                numInputs={6}
                separator={<span></span>}
                renderInput={(inputProps, index) => (
                  <input
                    {...inputProps}
                    type='text'
                    onKeyPress={(event) => handleKeyPress(event, index)}
                    className='input_box'
                    style={{
                      marginRight: "10px",
                      textAlign: "center",
                      padding: "5px",
                      width: "40px",
                      height: "40px",
                      border: "1px solid #5858df",
                      borderRadius: "10px",
                    }}
                  />
                )}
              />
              {errorRender && (
                <p style={{ color: "red" }}>
                  <b>Wrong OTP</b>
                </p>
              )}
              <div className='text-center'>
                <button
                  className='submit_button'
                  type='submit'
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className='footer'>
        <p>
          <b>Note:</b> If you find difficulties joining this video call, we
          suggest you update your browser to the latest version. Get in touch
          with us if you still find difficulties joining the call.
        </p>
      </footer>
    </>
  );
};

export default OtpScreen;
